/* You can add global styles to this file, and also import other style files */
:root {
  --vl-font: 'Rubik', sans-serif;
}

body {
  // reset
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.c {
  &-pointer {
    cursor: pointer;
  }
}

.row-center {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.vl-tickets {
  /* width */
  *::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  /* Track */
  *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(172, 172, 172);
    border-radius: 10px;
  }

  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: rgb(168, 168, 168);
    border-radius: 10px;
  }

  /* Handle on hover */
  *::-webkit-scrollbar-thumb:hover {
    background: #afafaf;
  }
}
